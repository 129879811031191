import DatePicker from "react-datepicker";

function ESummary(props: any) {
  return (
    <>
      <p className="quiz">
        <span>Daily Summary</span>
      </p>
      <div className="subtitle">
        <div className="summaryTxt">Summary date: </div>
        <DatePicker
          selected={props.summaryDate}
          className="datepicker"
          onChange={(date: Date) => {
            props.setSummaryDate(date);
          }}
        />
      </div>
      <p className="subtitle">{`Q: What did I do on ${props.dateStr(
        props.summaryDate
      )}?`}</p>
      <p className="terms">{props.summary}</p>
    </>
  );
}

export default ESummary;
