import axios from "axios";

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_OPENAI_API_URL}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
  },
});

export const instance1 = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API_URL}`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});
