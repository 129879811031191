function Terms(props: any) {
  return (
    <div className="layout">
      <div className="container">
        <p className="quiz">
          <span>Terms of Service</span>
        </p>
        <p className="terms">Last updated: March 30, 2023</p>
        <p className="subtitle">1. Acceptance of Terms</p>
        <p className="terms">
          By accessing and using Itaccomplish.com (the "Service"), you agree to
          be bound by these Terms of Service. If you do not agree with any part
          of these Terms, you must not use the Service.
        </p>
        <p className="subtitle">2. Eligibility</p>
        <p className="terms">
          By using the Service, you represent and warrant that you are at least
          18 years old or have the necessary legal capacity to form a binding
          contract under applicable law.
        </p>
        <p className="subtitle">3. Use of the Service</p>
        <p className="terms">
          3.1. Itaccomplish.com grants you a limited, non-exclusive,
          non-transferable, revocable license to access and use the Service
          solely for your personal or internal business purposes.
        </p>
        <p className="terms">
          3.2. You agree not to reproduce, duplicate, copy, sell, resell, or
          exploit any portion of the Service without our express written
          permission.
        </p>
        <p className="terms">
          3.3. You are responsible for ensuring that your use of the Service
          complies with all applicable laws and regulations.
        </p>
        <p className="subtitle">4. User Conduct</p>
        <p className="terms">
          4.1. You agree not to use the Service for any purpose that is unlawful
          or prohibited by these Terms.
        </p>
        <p className="terms">
          4.2. You agree not to engage in any activity that may interfere with
          or disrupt the Service or the servers and networks connected to the
          Service.
        </p>
        <p className="terms">
          4.3. You agree not to cheat, manipulate, or otherwise attempt to
          exploit the Service in any way.
        </p>
        <p className="terms">
          4.4. We reserve the right to investigate and take appropriate action,
          including blocking or terminating your access to the Service, if we
          believe that you have violated these Terms or engaged in any
          fraudulent or harmful activity.
        </p>
        <p className="subtitle">5. Content and Intellectual Property</p>
        <p className="terms">
          5.1. The content of the surveys you create and the survey responses
          you collect using the Service are your responsibility. We do not claim
          ownership of this content.
        </p>
        <p className="terms">
          5.2. You represent and warrant that you have all necessary rights,
          licenses, and permissions to use and share the content you submit
          through the Service.
        </p>
        <p className="terms">
          5.3. Itaccomplish.com and its licensors retain all rights, title, and
          interest in and to the Service, including all related intellectual
          property rights.
        </p>
        <p className="subtitle">6. Termination</p>
        <p className="terms">
          We may terminate or suspend your access to the Service at any time,
          without prior notice or liability, if we believe that you have
          breached these Terms or for any other reason at our sole discretion.
        </p>
        <p className="subtitle">7. Disclaimers and Limitation of Liability</p>
        <p className="terms">
          7.1. The Service is provided on an "as is" and "as available" basis,
          without warranties of any kind, either express or implied, including
          but not limited to warranties of merchantability, fitness for a
          particular purpose, or non-infringement.
        </p>
        <p className="terms">
          7.2. In no event shall Itaccomplish.com, its affiliates, or their
          respective officers, directors, employees, or agents be liable for any
          indirect, incidental, special, consequential, or punitive damages, or
          any loss of profits or revenues, whether incurred directly or
          indirectly, or any loss of data, use, goodwill, or other intangible
          losses, resulting from your use of the Service.
        </p>
        <p className="subtitle">8. Changes to the Terms</p>
        <p className="terms">
          We reserve the right to modify these Terms at any time. We will notify
          you of any changes by posting the updated Terms on this page. Your
          continued use of the Service after any changes to the Terms
          constitutes your acceptance of the new Terms.
        </p>
        <p className="subtitle">9. Governing Law</p>
        <p className="terms">
          These Terms shall be governed by and construed in accordance with the
          laws of [Your Jurisdiction], without regard to its conflict of law
          provisions.
        </p>
        <p className="subtitle">10. Contact Information</p>
        <p className="terms">
          If you have any questions or concerns about these Terms of Service,
          please contact us:
        </p>
        <p className="terms">By email: tgb29@itaccomplish.com</p>
      </div>
    </div>
  );
}

export default Terms;
