function Privacy() {
  return (
    <div className="layout">
      <div className="container">
        <p className="quiz">
          <span>Privacy Policy</span>
        </p>
        <p className="terms">Last updated: March 20, 2023</p>
        <p className="terms">
          Welcome to Itaccomplish.com. We understand that your privacy is
          important, and we are committed to protecting your personal
          information. This Privacy Policy outlines how we collect, use, store,
          and disclose your information when you use our website.
        </p>
        <p className="subtitle">1. Information Collection and Use</p>
        <p className="terms">
          We collect various types of information for different purposes,
          including:
        </p>
        <p className="terms">1.1. Personal Data</p>
        <p className="terms">
          When you use our website, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. This may include, but is not limited to:
        </p>
        <p className="terms">Email address</p>
        <p className="terms">First name and last name</p>
        <p className="terms">Cookies and usage data</p>
        <p className="terms">1.2. Usage Data</p>
        <p className="terms">
          We may also collect information about how our website is accessed and
          used. This may include:
        </p>
        <p className="terms">Browser type and version</p>
        <p className="terms">Operating system</p>
        <p className="terms">IP address</p>
        <p className="terms">Pages visited on our website</p>
        <p className="terms">Time and date of your visit</p>
        <p className="terms">Time spent on those pages</p>
        <p className="terms">1.3. Tracking & Cookies Data</p>
        <p className="terms">
          We use cookies and similar tracking technologies to track activity on
          our website and hold certain information. You can instruct your
          browser to refuse all cookies or to indicate when a cookie is being
          sent. However, if you do not accept cookies, you may not be able to
          use some portions of our website.
        </p>
        <p className="subtitle">2. Use of Data</p>
        <p className="terms">
          Itaccomplish.com uses the collected data for various purposes:
        </p>
        <p className="terms">To provide and maintain our website</p>
        <p className="terms">To improve and personalize user experience</p>
        <p className="terms">
          To communicate with you about changes, updates, or promotional
          materials
        </p>
        <p className="terms">
          To monitor usage and detect, prevent, and address technical issues
        </p>
        <p className="terms">To comply with legal obligations</p>
        <p className="subtitle">3. Data Retention</p>
        <p className="terms">
          We will retain your personal information only for as long as necessary
          to fulfill the purposes for which it was collected, including for the
          purposes of satisfying any legal, accounting, or reporting
          requirements.
        </p>
        <p className="subtitle">4. Disclosure of Data</p>
        <p className="terms">
          We may disclose your personal information in the good faith belief
          that such action is necessary to:
        </p>
        <p className="terms">Comply with a legal obligation</p>
        <p className="terms">Protect and defend our rights or property</p>
        <p className="terms">
          Prevent or investigate possible wrongdoing in connection with our
          website
        </p>
        <p className="terms">
          Protect the personal safety of users or the public
        </p>
        <p className="terms">Protect against legal liability</p>
        <p className="subtitle">5. Security of Data</p>
        <p className="terms">
          The security of your data is important to us, but remember that no
          method of transmission over the internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your personal data, we cannot guarantee its absolute
          security.
        </p>
        <p className="subtitle">6. Changes to This Privacy Policy</p>
        <p className="terms">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
        </p>
        <p className="subtitle">7. Contact Us</p>
        <p className="terms">
          If you have any questions or concerns about this Privacy Policy,
          please contact us:
        </p>
        <p className="terms">By email: tgb29@itaccomplish.com</p>
      </div>
    </div>
  );
}

export default Privacy;
