import { useState, useEffect } from "react";
import queryString from "query-string";
import { Link } from "react-router-dom";

const stringifiedParams = queryString.stringify({
  client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
  redirect_uri: process.env.REACT_APP_FACEBOOK_REDIRECT_URL,
  scope: ["email"].join(","),
  response_type: "code",
  auth_type: "rerequest",
  display: "popup",
});

const facebookLoginUrl = `https://www.facebook.com/v4.0/dialog/oauth?${stringifiedParams}`;

const linkedinLoginUrl = `${process.env.REACT_APP_BACKEND_API_URL}/linkedin`;

function Login(props: any) {
  const [err, setErr] = useState("");

  useEffect(() => {
    if (err)
      setTimeout(() => {
        setErr("");
      }, 2000);
  }, [err]);

  return (
    <div className="layout">
      <div className="fc-container">
        <div className="login-zone">
          <div className="content">
            <div>
              <a href={facebookLoginUrl}>
                <button className="fc-btn">Connect with Facebook</button>
              </a>
            </div>
            <div style={{ margin: "20px 0" }}>
              <a href={linkedinLoginUrl}>
                <button className="lk-btn">
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: 18,
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18px"
                        height="18px"
                        viewBox="0 0 430.117 430.117"
                      >
                        <g>
                          <path
                            d="M430.117,261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707   c-25.473,0-40.632,17.142-47.301,33.724c-2.432,5.928-3.058,14.179-3.058,22.477V420.56h-92.219c0,0,1.242-251.285,0-277.32h92.21   v39.309c-0.187,0.294-0.43,0.611-0.606,0.896h0.606v-0.896c12.251-18.869,34.13-45.824,83.102-45.824   C384.633,136.724,430.117,176.361,430.117,261.543z M52.183,9.558C20.635,9.558,0,30.251,0,57.463   c0,26.619,20.038,47.94,50.959,47.94h0.616c32.159,0,52.159-21.317,52.159-47.94C103.128,30.251,83.734,9.558,52.183,9.558z    M5.477,420.56h92.184v-277.32H5.477V420.56z"
                            fill="#FFFFFF"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div
                      style={{
                        width: 10,
                        borderRight: "1px solid #364e92",
                        height: 30,
                      }}
                    ></div>
                    <div
                      style={{
                        textAlign: "left",
                        width: "100%",
                        marginLeft: 10,
                      }}
                    >
                      Connect with LinkedIn
                    </div>
                  </div>
                </button>
              </a>
            </div>
            <div>
              <Link to={"/emaillogged"}>
                <button className="gl-btn">Connect with G-email</button>
              </Link>
            </div>

            {err && <p className="err">{err}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
