import { useState, useEffect, useRef } from "react";
import ArrowIcon from "../imgs/arrow1.png";
import { getChatCompletion } from "../api/post";
import { ClockLoader } from "react-spinners";
import { emailWords } from "../service";

interface IChatObj {
  owner: string;
  mention: string;
}

interface IPrompt {
  role: string;
  content: string;
}

function EWrite(props: any) {
  const messagesEndRef = useRef(null);

  const [chatObj, setChatObj] = useState<IChatObj[]>([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState<IPrompt[]>([]);

  let pIndex = 0;
  useEffect(() => {
    const createPrompt = async (sentEmails: any) => {
      if (sentEmails && sentEmails.length > 0) {
        pIndex++;
        let txt: string = `[`;
        for (let i = 0; i < sentEmails.length; i++) {
          txt += emailWords(sentEmails[i]["body"], 200) + " ";
        }

        setPrompt((current) => [
          ...current,
          {
            role: "system",
            content: `You are a highly intelligent email writing bot. Your goal is to help the user write emails in their voice and style. The user will give you emails they've written from their "Sent mailbox", and you must parse these emails and profile this person's writing style. When someone reads the email you write, the reader should believe it's from the user -- not you. `,
          },
          {
            role: "user",
            content: `Human: The following emais are from my "Sent mailbox": ${txt.slice(
              0,
              2000
            )}. \n Please parse these emails from my "Sent mailbox" and profile my writing style. Using my writing style, help me write an email that is from me to the recipient. Please infer who I am based on the emails in my "Sent mailbox." These emails emcompass what's going on in my daily life.`,
          },
          {
            role: "assistant",
            content: `AI: Tell me about the email you want to send, and I'll write an email from you based on the emails in your "Sent mailbox."`,
          },
        ]);
      }
    };
    if (pIndex === 0) {
      createPrompt(props.sentMsg);
    }
  }, [JSON.stringify(props.sentMsg)]);

  useEffect(() => {
    const getAnswer = async () => {
      setLoading(true);
      if (prompt.length === 0) {
        let obj = {
          owner: "com",
          mention: "No sent emails",
        };
        setChatObj((current) => [...current, obj]);
        setLoading(false);
        return;
      }
      let arr = [...prompt];
      arr.push({
        role: "user",
        content: `Human: ${chatObj[chatObj.length - 1].mention}`,
      });
      const result: any = await getChatCompletion(arr);
      const txt = result?.data?.choices?.[0]?.message?.content.trim();

      let obj = {
        owner: "com",
        mention: txt && typeof txt === "string" ? txt : "Invaild question",
      };
      arr.push({
        role: "assistant",
        content: `${txt && typeof txt === "string" ? txt : "Invaild question"}`,
      });
      setPrompt(arr);

      setChatObj((current) => [...current, obj]);
      setLoading(false);
    };

    if (chatObj.length > 0 && chatObj[chatObj.length - 1].owner === "you")
      getAnswer();

    // @ts-ignore
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [JSON.stringify(chatObj)]);

  const enterChat = (e: any) => {
    if (e.key === "Enter") {
      sendMsg();
    }
  };

  const sendMsg = () => {
    if (!query) return;

    let obj = { owner: "you", mention: query };
    setChatObj((current) => [...current, obj]);
    setQuery("");
  };

  let index = 0;
  useEffect(() => {
    if (index === 0) {
      index++;
      let obj = {
        owner: "com",
        mention: `Tell me about the email you want to send and I'll write it using your voice in your style.`,
      };
      setChatObj((current) => [...current, obj]);
    }
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {loading && (
        <div className="loading">
          <ClockLoader color="red" />
        </div>
      )}
      <p className="quiz">
        <span>Write Email</span>
      </p>
      <div
        className="fc-container"
        style={{ margin: "auto", textAlign: "left" }}
      >
        <div className="chat-zone">
          <div className="chat-content">
            {chatObj.map((cht: IChatObj, index: number) => (
              <div
                className={`chat-item ${cht.owner === "you" ? "ymsg" : "cmsg"}`}
                key={index}
              >
                <div className="mentioned">{cht.mention}</div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input">
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyUp={(e) => enterChat(e)}
            />
            <img src={ArrowIcon} alt="arrow" onClick={sendMsg} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EWrite;
