export function emailWords(str, number) {
  const txtArr = str.split(/[\r\n]+/);
  let arr = [];
  for (let i = 0; i < txtArr.length; i++) {
    if (
      !txtArr[i].includes("On Mon,") &&
      !txtArr[i].includes("On Tue,") &&
      !txtArr[i].includes("On Wed,") &&
      !txtArr[i].includes("On Thu,") &&
      !txtArr[i].includes("On Fri,") &&
      !txtArr[i].includes("On Sat,") &&
      !txtArr[i].includes("On Sun,")
    ) {
      arr.push(txtArr[i]);
    } else {
      break;
    }
  }
  return arr.join(" ").split(/\s+/).slice(0, number).join(" ");
}

export function randomStringRef(len) {
  var p = "abcdefghijklmnpqrstuvwxyz0123456789";

  return [...Array(len)].reduce((a) => a + p[~~(Math.random() * p.length)], "");
}

export function dateStr(date) {
  let str = "";
  str +=
    ("0" + (new Date(date).getMonth() + 1)).substr(-2) +
    "/" +
    ("0" + new Date(date).getDate()).substr(-2) +
    "/" +
    new Date(date).getFullYear();

  return str;
}
