import { instance, instance1 } from "../instance";

export const getCompletions = async (prompt) => {
  return await instance.post("/completions", {
    model: "text-davinci-003",
    prompt: prompt,
    temperature: 0.9,
    max_tokens: 150,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0.6,
    stop: [" Human:", " AI:"],
  });
};

export const getEmbedding = async (txt) => {
  return await instance.post(
    "/embeddings",
    {
      model: "text-embedding-ada-002",
      input: txt,
    },
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getChatCompletion = async (messages) => {
  return await instance.post("/chat/completions", {
    model: "gpt-3.5-turbo",
    messages: messages,
  });
};

export const emailinfo = async (data) => {
  return await instance1
    .post("/emailinfo", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const googleinfo = async (data) => {
  return await instance1
    .post("/googleinfo", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const saveEmbedding = async (data) => {
  return await instance1.post("/saveEmbedding", data);
};

export const pineconeQuery = async (data) => {
  return await instance1.post("/pineconeQuery", data);
};

export const getEmailData = async (data) => {
  return await instance1.post("/getEmailData", data);
};

export const saveEmailData = async (data) => {
  return await instance1.post("/saveEmailData", data);
};
