import { useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./App.scss";

import Login from "./pages/login";
import Logged from "./pages/logged";
import LinkedinLogged from "./pages/linkedinlogged";
import EmailLogged from "./pages/emaillogged";
import Chat from "./pages/chat";
import EChat from "./pages/echat";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";

function FIndex() {
  const [fcProfile, setFcProfile] = useState<any>(null);
  const [username, setUsername] = useState<string>("");
  const nav = useNavigate();

  const cancelHandle = () => {
    setFcProfile(null);
    nav("/");
  };

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/authenticate/facebook"
        element={<Logged fcProfile={fcProfile} setFcProfile={setFcProfile} />}
      />
      <Route
        path="/authenticate/linkedin"
        element={
          <LinkedinLogged fcProfile={fcProfile} setFcProfile={setFcProfile} />
        }
      />
      <Route
        path="/emaillogged"
        element={<EmailLogged username={username} setUsername={setUsername} />}
      />
      <Route
        path="/chat"
        element={<Chat fcProfile={fcProfile} cancelHandle={cancelHandle} />}
      />
      <Route path="/echat" element={<EChat username={username} />} />
      <Route path="/tos" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  );
}

export default FIndex;
