import { emailWords } from "../service";
const tableStyle = {
  width: "90%",
  margin: "auto",
  borderCollapse: "collapse" as "collapse",
};

const thStyle = {
  padding: "5px",
  border: "1px solid #32383e",
};

const tdStyle = {
  padding: "5px",
  border: "1px solid #32383e",
  textAlign: "left" as "left",
  whiteSpace: "pre-wrap" as "pre-wrap",
};

function EDetail(props: any) {
  return (
    <>
      <p className="quiz">
        <span>Email Details</span>
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "85%",
          margin: "20px auto",
          justifyContent: "end",
        }}
      >
        <select
          onChange={(e) => props.setBox(e.target.value)}
          value={props.box}
        >
          <option value="INBOX">Inbox</option>
          <option value="[Gmail]/Sent Mail">Sent</option>
          <option value="[Gmail]/All Mail">All</option>
        </select>
      </div>
      <div style={{ maxHeight: "650px", overflow: "auto" }}>
        {props.box === "INBOX" ? (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>From</th>
                <th style={thStyle}>Date</th>
                <th style={thStyle}>Subject</th>
              </tr>
            </thead>
            <tbody>
              {props.msg &&
                props.msg.map((item: any, index: number) => (
                  <tr key={index}>
                    <td style={tdStyle}>{item.header.from}</td>
                    <td style={tdStyle}>
                      {item.attrs.date.replace(/(T|.000Z)/gm, " ")}
                    </td>
                    <td style={tdStyle}>{item.header.subject}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : props.box === "[Gmail]/Sent Mail" ? (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>To</th>
                <th style={thStyle}>Date</th>
                <th style={thStyle}>Subject</th>
              </tr>
            </thead>
            <tbody>
              {props.sentMsg &&
                props.sentMsg.map((item: any, index: number) => (
                  <tr key={index}>
                    <td style={tdStyle}>{item.header.to}</td>
                    <td style={tdStyle}>
                      {item.attrs.date.replace(/(T|.000Z)/gm, " ")}
                    </td>
                    <td style={tdStyle}>{item.header.subject}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>From</th>
                <th style={thStyle}>To</th>
                <th style={thStyle}>Date</th>
                <th style={thStyle}>Subject</th>
              </tr>
            </thead>
            <tbody>
              {props.allMsg &&
                props.allMsg.map((item: any, index: number) => (
                  <tr key={index}>
                    <td style={tdStyle}>{item.header.from}</td>
                    <td style={tdStyle}>{item.header.to}</td>
                    <td style={tdStyle}>
                      {item.attrs.date.replace(/(T|.000Z)/gm, " ")}
                    </td>
                    <td style={tdStyle}>{item.header.subject}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>

      <button className="btn" style={{ marginTop: 20 }} onClick={props.save}>
        Save
      </button>
    </>
  );
}

export default EDetail;
