import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

function EFetch(props: any) {
  const navigate = useNavigate();

  return (
    <div className="container login">
      <div>
        <div className="intdiv">
          <div className="labelTxt">Email: </div>
          <input
            className="int"
            type="text"
            value={props.username}
            onChange={(e) => props.setUsername(e.target.value)}
          />
        </div>
        <div className="intdiv">
          <div className="labelTxt">Password: </div>
          <input
            className="int"
            type="password"
            value={props.password}
            onChange={(e) => props.setPassword(e.target.value)}
          />
        </div>
        <div className="intdiv">
          <div className="labelTxt">Start date: </div>
          <DatePicker
            selected={props.since}
            className="int"
            onChange={(date: Date) => {
              props.setSince(date);
              props.setSummaryDate(date);
            }}
          />
        </div>
        <div className="intdiv">
          <div className="labelTxt">From / To address (optional): </div>
          <input
            className="int"
            value={props.address}
            onChange={(e) => props.setAddress(e.target.value)}
          />
        </div>

        <p style={{ color: "red" }}>{props.err}</p>
        <button className="btn" onClick={props.onSubmit}>
          Connect
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            width: "85%",
            margin: "20px auto",
          }}
        >
          <div
            onClick={() => {
              navigate("/login");
            }}
            className="next-link back"
          >
            Back
          </div>
        </div>
      </div>
    </div>
  );
}

export default EFetch;
