import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../imgs/close.png";
import ArrowIcon from "../imgs/arrow1.png";
import { getChatCompletion, getEmbedding, pineconeQuery } from "../api/post";
import { ClockLoader } from "react-spinners";

interface IChatObj {
  owner: string;
  mention: string;
}

function EChat(props: any) {
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  const [chatObj, setChatObj] = useState<IChatObj[]>([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const createPrompt = async (question: string) => {
      const embeddingResult = await getEmbedding(question);
      let prompt: string = `I am a highly intelligent bot that uses your "Emails" to answer questions. If you ask me a question, I'll give you an answer based on your "Emails".  Your name is ${props.username}\n\n"Emails": "`;
      if (
        embeddingResult &&
        embeddingResult.data &&
        embeddingResult.data.data &&
        embeddingResult.data.data.length > 0 &&
        embeddingResult.data.data[0].embedding
      ) {
        const queryArr = embeddingResult.data.data[0].embedding;
        const topMatch = await pineconeQuery({
          username: props.username,
          vector: JSON.stringify(queryArr),
        });
        if (topMatch.data && topMatch.data.matches) {
          for (let i = 0; i < topMatch.data.matches.length; i++) {
            if (
              topMatch.data.matches[i].metadata &&
              topMatch.data.matches[i].metadata.text
            )
              prompt += topMatch.data.matches[i].metadata.text;
          }
        }
      }
      return prompt;
    };

    const getAnswer = async () => {
      setLoading(true);
      const prompt = await createPrompt(chatObj[chatObj.length - 1].mention);
      let arr = [];
      arr.push({
        role: "system",
        content: `${prompt.slice(0, 7000)}"\n\n`,
      });
      arr.push({
        role: "user",
        content: `Q: ${chatObj[chatObj.length - 1].mention}`,
      });

      const result: any = await getChatCompletion(arr);
      const txt = result?.data?.choices?.[0]?.message?.content.trim();

      let obj = {
        owner: "com",
        mention: txt && typeof txt === "string" ? txt : "Invaild question",
      };
      setChatObj((current) => [...current, obj]);
      setLoading(false);
    };

    if (chatObj.length > 0 && chatObj[chatObj.length - 1].owner === "you")
      getAnswer();

    // @ts-ignore
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [JSON.stringify(chatObj)]);

  const enterChat = (e: any) => {
    if (e.key === "Enter") {
      sendMsg();
    }
  };

  const sendMsg = () => {
    if (!query) return;

    let obj = { owner: "you", mention: query };
    setChatObj((current) => [...current, obj]);
    setQuery("");
  };

  return (
    <div className="layout">
      {loading && (
        <div className="loading">
          <ClockLoader color="red" />
        </div>
      )}
      <div className="fc-container">
        <div className="chat-zone">
          <div className="cancel">
            <img
              src={CloseIcon}
              alt="close"
              onClick={() => navigate("/emaillogged")}
            />
          </div>
          <div className="chat-content">
            {chatObj.map((cht: IChatObj, index: number) => (
              <div
                className={`chat-item ${cht.owner === "you" ? "ymsg" : "cmsg"}`}
                key={index}
              >
                <div className="mentioned">{cht.mention}</div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input">
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyUp={(e) => enterChat(e)}
            />
            <img src={ArrowIcon} alt="arrow" onClick={sendMsg} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EChat;
