import { useEffect, useState } from "react";
import { ClockLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import {
  emailinfo,
  getEmailData,
  getEmbedding,
  saveEmailData,
  saveEmbedding,
  getChatCompletion,
} from "../api/post";

import CryptoJS from "crypto-js";
import { ToastContainer, toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import EFetch from "../components/efetch";
import EDetail from "../components/edetail";
import ESummary from "../components/esummary";
import EWrite from "../components/ewrite";
import { randomStringRef, dateStr, emailWords } from "../service";

function EmailLogged(props: any) {
  const [password, setPassword] = useState("");
  const [since, setSince] = useState(new Date());
  const [address, setAddress] = useState("");
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [msg, setMsg] = useState([]);
  const [sentMsg, setSentMsg] = useState([]);
  const [allMsg, setAllMsg] = useState([]);
  const [summaryDate, setSummaryDate] = useState(new Date());
  const [summary, setSummary] = useState("");

  const [flag, setFlag] = useState(false);
  const [subFlag, setSubFlag] = useState("edetail");
  const [box, setBox] = useState("INBOX");

  const navigate = useNavigate();

  const onSubmit = async (event: any) => {
    event?.preventDefault();

    setErr("");
    setLoading(true);
    const encryptedPassword = CryptoJS.AES.encrypt(
      password,
      process.env.REACT_APP_SECRET_KEY ? process.env.REACT_APP_SECRET_KEY : ""
    ).toString();

    const email = props.username;
    const emailDataRes = await getEmailData({ username: props.username });
    if (emailDataRes.data) {
      setEmailData(emailDataRes.data);
    }
    getEmail(email, encryptedPassword, since, address, "INBOX");
    getEmail(email, encryptedPassword, since, address, "[Gmail]/Sent Mail");
    getEmail(email, encryptedPassword, since, address, "[Gmail]/All Mail");
  };

  useEffect(() => {
    const getSummary = async (date: Date) => {
      setSummary("");
      setSummaryLoading(true);

      let syscontent = `You are a highly intelligent bot who can summarize a list of emails. You will be given a list of emails for a single day, and you will summarize the day based on all the emails. The following contains my emails:\n\nEmails: "`;
      const eData = emailData.filter(
        (i: any) =>
          dateStr(i.date.S.replace(/(T|.000Z)/gm, " ")) === dateStr(date)
      );
      if (eData.length > 0) {
        for (let i = 0; i < eData.length; i++) {
          syscontent += eData[i]["text"]["S"];
        }
        let prompt = [
          {
            role: "system",
            content: `${syscontent.slice(0, 2500)}"\n\n`,
          },
          {
            role: "user",
            content: `Q: What did you do on ${date}?\n\n`,
          },
          {
            role: "user",
            content: `Q: My email is ${props.username}. Based on the emails, what happened today?`,
          },
        ];

        const result: any = await getChatCompletion(prompt);
        const txt = result?.data?.choices?.[0]?.message?.content.trim();
        setSummary(txt);
      } else {
        setSummary("A: No data");
      }
      setSummaryLoading(false);
    };
    getSummary(summaryDate);
  }, [summaryDate, emailData]);

  const getEmail = async (
    email: string,
    encryptedPassword: string,
    date: Date,
    address: string,
    box: string
  ) => {
    const result: any = await emailinfo({
      email,
      encryptedPassword,
      date,
      address,
      box,
    });

    if (result && result.data && result.data.result) {
      if (box === "INBOX") {
        setMsg(result.data.result);
      } else if (box === "[Gmail]/Sent Mail") {
        console.log("Sent emails", result.data.result);
        setSentMsg(result.data.result);
      } else {
        setAllMsg(result.data.result);
      }
      setFlag(true);
      setLoading(false);
      return;
    } else if (result && result.data && result.data.error) {
      console.log(result.data.error);
      setErr(result.data.error)
      if (box === "INBOX") {
        setMsg([]);
      } else if (box === "[Gmail]/Sent Mail") {
        setSentMsg([]);
      } else {
        setAllMsg([]);
      }
      setLoading(false);
    } else {
      setErr("Invaild request");
      setLoading(false);
    }
  };

  const save = async () => {
    try {
      setLoading(true);
      const msgArr =
        box === "INBOX" ? msg : box === "[Gmail]/Sent Mail" ? sentMsg : allMsg;
      for (let i = 0; i < msgArr.length; i++) {
        const isSavedData = emailData.filter(
          (item: any) => item.date.S === msgArr[i]["attrs"]["date"]
        );
        if (isSavedData.length > 0) continue;

        const bodytxt: string = msgArr[i]["body"]
          ? emailWords(msgArr[i]["body"], 200)
          : "";
        const embeddingResult = await getEmbedding(bodytxt.slice(0, 7000));
        if (
          embeddingResult &&
          embeddingResult.data &&
          embeddingResult.data.data &&
          embeddingResult.data.data.length > 0 &&
          embeddingResult.data.data[0].embedding
        ) {
          await saveEmailData({
            id: randomStringRef(8),
            username: props.username,
            date: msgArr[i]["attrs"]["date"],
            text: bodytxt.slice(0, 1000),
          });
          await saveEmbedding({
            username: props.username,
            embedding: JSON.stringify(embeddingResult.data.data[0].embedding),
            emailid: msgArr[i]["attrs"]["x-gm-msgid"],
            text: bodytxt.slice(0, 1000),
          });
        }
      }
      const emailDataRes = await getEmailData({ username: props.username });
      if (emailDataRes.data) {
        setEmailData(emailDataRes.data);
      }
      const successTxt =
        box === "INBOX"
          ? "Inbox emails have been saved."
          : box === "[Gmail]/Sent Mail"
          ? "Sent emails have been saved."
          : "Your emails have been saved.";
      toast.success(successTxt);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.warning("Failed to save emails");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="layout">
        {(loading || summaryLoading) && (
          <div className="loading">
            <ClockLoader color="red" />
          </div>
        )}{" "}
        {flag ? (
          <div className="container">
            {subFlag === "edetail" ? (
              <EDetail
                box={box}
                setBox={setBox}
                msg={msg}
                sentMsg={sentMsg}
                allMsg={allMsg}
                save={save}
              />
            ) : subFlag === "esummary" ? (
              <ESummary
                summaryDate={summaryDate}
                summary={summary}
                setSummaryDate={setSummaryDate}
                dateStr={dateStr}
              />
            ) : (
              <EWrite sentMsg={sentMsg} />
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignContent: "center",
                width: "85%",
                margin: "20px auto",
                marginTop: 100,
              }}
            >
              <div
                onClick={() => {
                  setFlag(false);
                  setErr("");
                  setBox("INBOX");
                  setEmailData([]);
                  setSummary("");
                  setSubFlag("edetail");
                }}
                className="next-link back"
              >
                Back
              </div>
              {subFlag !== "edetail" && (
                <div
                  className="next-link"
                  onClick={() => setSubFlag("edetail")}
                >
                  Emails
                </div>
              )}
              {subFlag !== "esummary" && (
                <div
                  className="next-link"
                  onClick={() => setSubFlag("esummary")}
                >
                  Daily Summary
                </div>
              )}
              {subFlag !== "ewrite" && (
                <div className="next-link" onClick={() => setSubFlag("ewrite")}>
                  Write Email
                </div>
              )}

              <div
                className="next-link next"
                onClick={() => navigate("/echat")}
              >
                Chat
              </div>
            </div>
          </div>
        ) : (
          <EFetch
            username={props.username}
            password={password}
            since={since}
            address={address}
            err={err}
            setUsername={props.setUsername}
            setPassword={setPassword}
            setSince={setSince}
            setSummaryDate={setSummaryDate}
            setAddress={setAddress}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </>
  );
}

export default EmailLogged;
