import { useState, useEffect } from "react";
import queryString from "query-string";
import { ClockLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function LinkedinLogged(props: any) {
  const [url, setUrl] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    setUrl(urlParams);
  }, []);

  useEffect(() => {
    if (url && url.first_name)
      props.setFcProfile({ profile: { first_name: url.first_name } });
  }, [url]);

  useEffect(() => {
    if (props.fcProfile) navigate("/chat");
  }, [props.fcProfile]);

  return (
    <div className="layout">
      <div className="loading">
        <ClockLoader color="red" />
      </div>
    </div>
  );
}

export default LinkedinLogged;
