import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../imgs/close.png";
import ArrowIcon from "../imgs/arrow1.png";
import { getChatCompletion } from "../api/post";
import { ClockLoader } from "react-spinners";

interface IChatObj {
  owner: string;
  mention: string;
}

function Chat(props: any) {
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  const [chatObj, setChatObj] = useState<IChatObj[]>([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAnswer = async () => {
      if (
        props.fcProfile &&
        props.fcProfile.profile &&
        props.fcProfile.profile.first_name
      ) {
        setLoading(true);
        let prompt = [];
        prompt.push(
          {
            role: "system",
            content: `The following is a conversation with a personalized AI assistant. The assistant is helpful, creative, clever, and very friendly. The AI is personalized and addresses the person they're speaking with by name. In this conversation, the AI is speaking with ${props.fcProfile.profile.first_name}.\n\n`,
          },
          {
            role: "user",
            content: `${props.fcProfile.profile.first_name}: Hello, who are you?\n`,
          },
          {
            role: "assistant",
            content: `AI: I am an AI created by OpenAI. How can I help you today, ${props.fcProfile.profile.first_name}?\n`,
          },
          {
            role: "user",
            content: `${props.fcProfile.profile.first_name}: ${
              chatObj[chatObj.length - 1].mention
            }`,
          }
        );

        const result: any = await getChatCompletion(prompt);
        const txt = result?.data?.choices?.[0]?.message?.content.trim();

        let obj = {
          owner: "com",
          mention: txt && typeof txt === "string" ? txt : "Invaild question",
        };
        setChatObj((current) => [...current, obj]);
        setLoading(false);
      } else {
        navigate("/");
      }
    };

    if (chatObj.length > 0 && chatObj[chatObj.length - 1].owner === "you")
      getAnswer();

    // @ts-ignore
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [JSON.stringify(chatObj)]);

  let index = 0;
  useEffect(() => {
    if (
      index === 0 &&
      props.fcProfile &&
      props.fcProfile.profile &&
      props.fcProfile.profile.first_name
    ) {
      index++;
      let obj = {
        owner: "com",
        mention: `I am an AI created by OpenAI. How can I help you today, ${props.fcProfile.profile.first_name}.`,
      };
      setChatObj((current) => [...current, obj]);
    }
  }, []);

  const enterChat = (e: any) => {
    if (e.key === "Enter") {
      sendMsg();
    }
  };

  const sendMsg = () => {
    if (!query) return;

    let obj = { owner: "you", mention: query };
    setChatObj((current) => [...current, obj]);
    setQuery("");
  };

  return (
    <div className="layout">
      {loading && (
        <div className="loading">
          <ClockLoader color="red" />
        </div>
      )}
      <div className="fc-container">
        <div className="chat-zone">
          <div className="cancel">
            <img src={CloseIcon} alt="close" onClick={props.cancelHandle} />
          </div>
          <div className="chat-content">
            {chatObj.map((cht: IChatObj, index: number) => (
              <div
                className={`chat-item ${cht.owner === "you" ? "ymsg" : "cmsg"}`}
                key={index}
              >
                <div className="mentioned">{cht.mention}</div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input">
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyUp={(e) => enterChat(e)}
            />
            <img src={ArrowIcon} alt="arrow" onClick={sendMsg} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
