import { useState, useEffect } from "react";
import queryString from "query-string";
import { ClockLoader } from "react-spinners";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Logged(props: any) {
  const [url, setUrl] = useState<any>(null);
  const [accessToken, setAccessToken] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    setUrl(urlParams);
  }, []);

  useEffect(() => {
    const getAccessTokenFromCode = async (code: string) => {
      const { data } = await axios({
        url: "https://graph.facebook.com/v4.0/oauth/access_token",
        method: "get",
        params: {
          client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
          client_secret: process.env.REACT_APP_FACEBOOK_APP_SECRET,
          redirect_uri: process.env.REACT_APP_FACEBOOK_REDIRECT_URL,
          code,
        },
      });
      if (data.access_token) setAccessToken(data.access_token);
    };

    if (url && url.code) getAccessTokenFromCode(url.code);
  }, [url]);

  useEffect(() => {
    const getFacebookUserData = async (access_token: string) => {
      const profile = await axios({
        url: "https://graph.facebook.com/v16.0/me",
        method: "get",
        params: {
          fields: [
            "id",
            "email",
            "first_name",
            "last_name",
            "picture",
            "about",
            "age_range",
            "birthday",
            "education",
            "favorite_athletes",
            "favorite_teams",
            "gender",
            "hometown",
            "inspirational_people",
            "quotes",
            "significant_other",
            "sports",
            "website",
          ].join(","),
          access_token: access_token,
        },
      });
      const groups = await axios({
        url: "https://graph.facebook.com/v16.0/me/groups",
        method: "get",
        params: {
          access_token: access_token,
        },
      });
      const posts = await axios({
        url: "https://graph.facebook.com/v16.0/me/posts",
        method: "get",
        params: {
          access_token: access_token,
        },
      });
      const likes = await axios({
        url: "https://graph.facebook.com/v16.0/me/likes",
        method: "get",
        params: {
          access_token: access_token,
        },
      });
      props.setFcProfile({
        profile: profile.data,
        groups: groups.data.data,
        posts: posts.data.data,
        likes: likes.data.data,
      });
    };

    if (accessToken) getFacebookUserData(accessToken);
  }, [accessToken]);

  useEffect(() => {
    if (props.fcProfile) navigate("/chat");
  }, [props.fcProfile]);

  return (
    <div className="layout">
      <div className="loading">
        <ClockLoader color="red" />
      </div>
    </div>
  );
}

export default Logged;
